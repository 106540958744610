import layout from '../view/layout'

const router = {
  name: 'wechat',
  path: 'wechat',
  component: layout,
  meta: {
    title: '微信',
    ico: 'el-icon-chat-line-round',
    authkey: 'wechat'
  },
  children: [
    {
      name: 'wechat_index',
      path: 'index',
      component: () => import('../view/wechat/index'),
      meta: {
        title: '公众账号',
        ico: '',
        isShow: true,
        authkey: 'wechat_index'
      }
    },
    {
      name: 'wechat_template',
      path: 'template',
      component: () => import('../view/wechat/template'),
      meta: {
        title: '小程序模板',
        ico: '',
        isShow: true,
        authkey: 'wechat_template'
      }
    },
    {
      name: 'wechat_audit',
      path: 'audit',
      component: () => import('../view/wechat/audit'),
      meta: {
        title: '审核列表',
        ico: '',
        isShow: true,
        authkey: 'wechat_audit'
      }
    }
  ]
}

export default router
