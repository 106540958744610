import Vue from 'vue';
import Router from 'vue-router';
import main from '../view/main'
import NotFoundComponent from '../view/404'
import personRouter from '../router/person.router'
import sysRouter from '../router/sys.router'
import userRouter from '../router/user.router'
import wechatRouter from '../router/wechat.router'
import auditRouter from '../router/audit.router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(Router);

const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('../view/login'),
    meta: { title: '登录' }
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    name: 'home',
    path: '/',
    component: main,
    redirect: '/person',
    children: [

    ]
  },
  {
    path: '*',
    component: NotFoundComponent
  }
];

routes[2].children.push(personRouter)
routes[2].children.push(userRouter)
routes[2].children.push(wechatRouter)
routes[2].children.push(auditRouter)
routes[2].children.push(sysRouter)

// add route path
// routes.forEach(route => {
  // route.path = route.path || '/' + (route.name || '');
// });

const RouterConfig = {
  // mode: 'hash',
  mode: 'history',
  routes: routes
}
const router = new Router(RouterConfig);

router.beforeEach((to, from, next) => {
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  NProgress.start()
  next();
});

router.afterEach(() => {
  NProgress.done()
})

NProgress.configure({ease:'linear',speed: 500});

export {
  router
};
