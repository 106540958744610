import layout from '../view/layout'

const router = {
  name: 'user',
  path: 'user',
  component: layout,
  meta: {
    title: '用户',
    ico: 'el-icon-user-solid',
    authkey: 'user'
  },
  children: [
    {
      name: 'user_index',
      path: 'index',
      component: () => import('../view/user/index'),
      meta: {
        title: '后台用户',
        ico: '',
        isShow: true,
        authkey: 'user_index'
      }
    },
    {
      name: 'user_add',
      path: 'add',
      component: () => import('../view/user/add'),
      meta: {
        title: '用户添加',
        ico: '',
        isShow: false,
        authkey: 'user_index'
      }
    },
    {
      name: 'user_group',
      path: 'group',
      component: () => import('../view/user/group'),
      meta: {
        title: '后台群组',
        ico: '',
        isShow: true,
        authkey: 'user_group'
      }
    },
    {
      name: 'user_group_add',
      path: 'group/add',
      component: () => import('../view/user/groupadd'),
      meta: {
        title: '群组添加',
        ico: '',
        isShow: false,
        authkey: 'user_group'
      }
    },
    {
      name: 'user_merchant',
      path: 'merchant',
      component: () => import('../view/user/merchant'),
      meta: {
        title: '商户管理',
        ico: '',
        isShow: true,
        authkey: 'user_merchant'
      }
    },
    {
      name: 'user_merchant_add',
      path: 'merchant/add',
      component: () => import('../view/user/merchantadd'),
      meta: {
        title: '商户添加',
        ico: '',
        isShow: false,
        authkey: 'user_merchant'
      }
    }
  ]
}

export default router
