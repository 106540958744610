import { fetch } from '@/utils/request';

// 微信
const wechatIndex = (param, callback) => fetch('/super/wechat/index','GET',param, callback);
const wechatTemplateIndex = (param, callback) => fetch('/super/wechat/template/list','GET',param, callback);
const rsyncDraft = (param, callback) => fetch('/super/wechat/draft/rsync','POST',param, callback);
const addDraft = (param, callback) => fetch('/super/wechat/draft/add','POST',param, callback);
const templateDel = (param, callback) => fetch('/super/wechat/template/del','POST',param, callback);

// 体验者
const wechatTestIndex = (param, callback) => fetch('/super/wechat/tester/index','GET',param, callback);
const wechatTestBand = (param, callback) => fetch('/super/wechat/tester/bind','POST',param, callback);
const wechatTestUnBand = (param, callback) => fetch('/super/wechat/tester/unbind','POST',param, callback);
const wechatExperienceQrcode = (param, callback) => fetch('/super/wechat/experience/qrcode','GET',param, callback);

const wechatAuditIndex = (param, callback) => fetch('/super/wechat/audit/index','GET',param, callback); //获取审核列表
const wechatAuditSubmit = (param, callback) => fetch('/super/wechat/audit/submit','POST',param, callback); //审核
const wechatAuditUndo = (param, callback) => fetch('/super/wechat/audit/undo','POST',param, callback); //撤销审核
const wechatAuditStatus = (param, callback) => fetch('/super/wechat/audit/status','POST',param, callback); //审核状态

export default {
  wechatIndex,
  wechatTemplateIndex,
  rsyncDraft,
  addDraft,
  templateDel,
  wechatTestIndex,
  wechatTestBand,
  wechatTestUnBand,
  wechatExperienceQrcode,
  wechatAuditIndex,
  wechatAuditSubmit,
  wechatAuditUndo,
  wechatAuditStatus
}
