import Vue from 'vue';
import App from './App';
import { router } from './router/router';
import config from './config/config'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/ele-ui/index.css'
Vue.use(ElementUI);
import utils from './utils/util'

import api from './service/api';
import configure from './config/configure'
Vue.prototype.$api = api;
Vue.prototype.$configure = configure

Vue.prototype.showLoading = utils.showLoading
Vue.prototype.hideLoading = utils.hideLoading
Vue.prototype.tips = utils.tips
Vue.prototype.success = utils.success
Vue.prototype.fail = utils.fail
Vue.prototype.reBack = utils.reBack
Vue.prototype.isEmpty = utils.isEmpty
Vue.prototype.config = config

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});

