import { Loading } from 'element-ui'

var loadingObj;

// 轻提示
function tips(msg) {
  this.$message({
    showClose: true,
    message: msg
  });
}

// 加载中
function showLoading(msg) {
  var options = {
    text: '加载中..',
    background: 'rgba(0, 0, 0, 0.7)'
  }
  loadingObj = Loading.service(options);
}

// 加载中
function hideLoading() {
  if(loadingObj) {
    loadingObj.close()
    loadingObj = ''
  }
}

// 成功
function success(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
}

// 失败
function fail(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
}

// 返回
function reBack() {
  // this.$router.go(-1)
  window.history.go(-1)
}

// 判断是否为空
function isEmpty(obj) {
  if (obj == null) {
    return true;
  }
  // 检查是否为数组且长度为0
  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }
  // 检查是否为对象且没有可枚举属性
  if (typeof obj === 'object' && Object.keys(obj).length === 0) {
    return true;
  }
  // 处理特殊情况，如数字0、空字符串''和布尔值false（可选）
  // 注意：这些通常不被认为是“空对象”，但可以根据需要包含
  if (obj === 0 || obj === '' || obj === false) {
    return true;
  }
  return false;
}

export default {
  tips,
  showLoading,
  hideLoading,
  success,
  fail,
  reBack,
  isEmpty
}
