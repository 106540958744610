import shop from './shop'
import merchant from './merchant'
import wechat from "@/service/wechat";
import audit from "@/service/audit";

export default {
  shop,
  merchant,
  wechat,
  audit
}
