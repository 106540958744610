import { fetch } from '@/utils/request';

// 基础
const authLogin = (param, callback) => fetch('/super/auth/login','POST',param, callback);
const authLogout = (callback) => fetch('/super/auth/logout','POST',false, callback);
const myIndex = (callback) => fetch('/super/my/index','GET',{}, callback);
const myUpdate = (param, callback) => fetch('/super/my/update','POST',param, callback);
const myPrivs = (callback) => fetch('/super/my/privs','GET',{}, callback);
const myUpdatePwd = (param, callback) => fetch('/super/my/resetpwd','POST',param, callback);

// 文件
const FileUpload = (param, callback) => fetch('/super/common/uploadimg','POST',param, callback);
const fileList = (param, callback) => fetch('/super/common/filelist','GET',param, callback);
const filedel = (param, callback) => fetch('/super/common/filedel','POST',param, callback);

// 用户
const userIndex = (param, callback) => fetch('/super/user/index','GET', param, callback);
const userDetail = (param, callback) => fetch('/super/user/detail','GET', param, callback);
const userAdd = (param, callback) => fetch('/super/user/add','POST', param, callback);
const userEdit = (param, callback) => fetch('/super/user/edit','POST', param, callback);
const userDelete = (param, callback) => fetch('/super/user/del','POST', param, callback);
const shenheDelete = (param, callback) => fetch('/super/user/shenhe','POST', param, callback);
const userVisitLog = (param, callback) => fetch('/super/user/visitlog','GET', param, callback);

// 群组
const groupIndex = (param, callback) => fetch('/super/group/index','GET', param, callback);
const groupDetail = (param, callback) => fetch('/super/group/detail','GET', param, callback);
const groupAdd = (param, callback) => fetch('/super/group/add','POST', param, callback);
const groupEdit = (param, callback) => fetch('/super/group/edit','POST', param, callback);
const groupDelete = (param, callback) => fetch('/super/group/del','POST', param, callback);

// 后台菜单
const menuIndex = (param, callback) => fetch('/super/menu/index','GET', param, callback);
const menuDetail = (param, callback) => fetch('/super/menu/detail','GET', param, callback);
const menuAdd = (param, callback) => fetch('/super/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/super/menu/edit','POST', param, callback);
const menuDelete = (param, callback) => fetch('/super/menu/del','POST', param, callback);

// 版本
const versionIndex = (param, callback) => fetch('/super/version/index','GET', param, callback);
const versionDetail = (param, callback) => fetch('/super/version/detail','GET', param, callback);
const versionAdd = (param, callback) => fetch('/super/version/add','POST', param, callback);
const versionEdit = (param, callback) => fetch('/super/version/edit','POST', param, callback);
const versionDelete = (param, callback) => fetch('/super/version/del','POST', param, callback);

// 商户
const merchantIndex = (param, callback) => fetch('/super/merchant/index','GET', param, callback);
const merchantDetail = (param, callback) => fetch('/super/merchant/detail','GET', param, callback);
const merchantAdd = (param, callback) => fetch('/super/merchant/add','POST', param, callback);
const merchantEdit = (param, callback) => fetch('/super/merchant/edit','POST', param, callback);
const merchantShenhe = (param, callback) => fetch('/super/merchant/shenhe','POST', param, callback);
const merchantDelete = (param, callback) => fetch('/super/merchant/del','POST', param, callback);
const merchantRenew = (param, callback) => fetch('/super/merchant/renew','POST', param, callback);
const merchantVisitLog = (param, callback) => fetch('/super/merchant/visitlog','GET', param, callback);

// 商户菜单
const merchantMenuIndex = (param, callback) => fetch('/super/merchant/menu/index','GET', param, callback);
const merchantMenuDetail = (param, callback) => fetch('/super/merchant/menu/detail','GET', param, callback);
const merchantMenuAdd = (param, callback) => fetch('/super/merchant/menu/add','POST', param, callback);
const merchantMenuEdit = (param, callback) => fetch('/super/merchant/menu/edit','POST', param, callback);
const merchantMenuDelete = (param, callback) => fetch('/super/merchant/menu/del','POST', param, callback);

// 商户功能菜单
const functionMenuIndex = (param, callback) => fetch('/super/function/menu/index','GET', param, callback);
const functionMenuDetail = (param, callback) => fetch('/super/function/menu/detail','GET', param, callback);
const functionMenuAdd = (param, callback) => fetch('/super/function/menu/add','POST', param, callback);
const functionMenuEdit = (param, callback) => fetch('/super/function/menu/edit','POST', param, callback);
const functionMenuDelete = (param, callback) => fetch('/super/function/menu/del','POST', param, callback);

// 版本
const noticeIndex = (param, callback) => fetch('/super/sys/notice/index','GET', param, callback);
const noticeDetail = (param, callback) => fetch('/super/sys/notice/detail','GET', param, callback);
const noticeAdd = (param, callback) => fetch('/super/sys/notice/add','POST', param, callback);
const noticeEdit = (param, callback) => fetch('/super/sys/notice/edit','POST', param, callback);
const noticeDelete = (param, callback) => fetch('/super/sys/notice/del','POST', param, callback);

export default {
  authLogin,
  authLogout,
  myIndex,
  myUpdate,
  myPrivs,
  myUpdatePwd,
  FileUpload,
  fileList,
  filedel,
  userIndex,
  userDetail,
  userAdd,
  userEdit,
  userDelete,
  shenheDelete,
  userVisitLog,
  groupIndex,
  groupDetail,
  groupAdd,
  groupEdit,
  groupDelete,
  menuIndex,
  menuDetail,
  menuAdd,
  menuEdit,
  menuDelete,
  versionIndex,
  versionDetail,
  versionAdd,
  versionEdit,
  versionDelete,
  merchantIndex,
  merchantDetail,
  merchantAdd,
  merchantEdit,
  merchantShenhe,
  merchantDelete,
  merchantRenew,
  merchantVisitLog,
  merchantMenuIndex,
  merchantMenuDetail,
  merchantMenuAdd,
  merchantMenuEdit,
  merchantMenuDelete,
  functionMenuIndex,
  functionMenuDetail,
  functionMenuDelete,
  functionMenuAdd,
  functionMenuEdit,
  noticeIndex,
  noticeDetail,
  noticeAdd,
  noticeEdit,
  noticeDelete
}
