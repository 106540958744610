export default {

    // 使用年限
    yearList: [
        {id:1,title:'1月'},
        {id:2,title:'2月'},
        {id:3,title:'3月'},
        {id:4,title:'6月'},
        {id:5,title:'1年'},
        {id:6,title:'2年'},
    ],

}
