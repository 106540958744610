import layout from '../view/layout'

const router = {
  name: 'audit',
  path: 'audit',
  component: layout,
  meta: {
    title: '审核',
    ico: 'el-icon-open',
    authkey: 'audit'
  },
  children: [
    {
      name: 'audit_pic',
      path: 'pic',
      component: () => import('../view/audit/pic'),
      meta: {
        title: '图片审核',
        ico: '',
        isShow: true,
        authkey: 'audit_pic'
      }
    }
  ]
}

export default router
