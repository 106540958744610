import layout from '../view/layout'

const router = {
  name: 'sys',
  path: 'sys',
  component: layout,
  meta: {
    title: '系统',
    ico: 'el-icon-s-tools',
    authkey: 'sys'
  },
  children: [
    {
      name: 'sys_menu',
      path: 'menu',
      component: () => import('../view/sys/menu'),
      meta: {
        title: '后台菜单',
        ico: '',
        isShow: true,
        authkey: 'sys_menu'
      }
    },
    {
      name: 'sys_visitlog',
      path: 'visitlog',
      component: () => import('../view/sys/visitlog'),
      meta: {
        title: '后台日志',
        ico: '',
        isShow: true,
        authkey: 'sys_visitlog'
      }
    },
    {
      name: 'sys_version',
      path: 'version',
      component: () => import('../view/sys/version'),
      meta: {
        title: '产品版本',
        ico: '',
        isShow: true,
        authkey: 'sys_version'
      }
    },
    {
      name: 'sys_version_add',
      path: 'version/add',
      component: () => import('../view/sys/versionadd'),
      meta: {
        title: '版本添加',
        ico: '',
        isShow: false,
        authkey: 'sys_version'
      }
    },
    {
      name: 'sys_merchant_menu',
      path: 'merchant/menu',
      component: () => import('../view/sys/merchant_menu'),
      meta: {
        title: '商户菜单',
        ico: '',
        isShow: true,
        authkey: 'sys_merchant_menu'
      }
    },
    {
      name: 'sys_merchant_visitlog',
      path: 'merchant/visitlog',
      component: () => import('../view/sys/merchant_visitlog'),
      meta: {
        title: '商户日志',
        ico: '',
        isShow: true,
        authkey: 'sys_merchant_visitlog'
      }
    },
    {
      name: 'sys_function_menu',
      path: 'function/menu',
      component: () => import('../view/sys/function_menu'),
      meta: {
        title: '功能菜单',
        ico: '',
        isShow: true,
        authkey: 'sys_function_menu'
      }
    },
    {
      name: 'sys_notice',
      path: 'notice',
      component: () => import('../view/sys/notice'),
      meta: {
        title: '系统公告',
        ico: '',
        isShow: true,
        authkey: 'sys_notice'
      }
    },
    {
      name: 'sys_notice_add',
      path: 'notice/add',
      component: () => import('../view/sys/notice_add'),
      meta: {
        title: '公告添加',
        ico: '',
        isShow: false,
        authkey: 'sys_notice'
      }
    }
  ]
}

export default router
